<template>
    <v-row >
        <v-col cols="4"> <v-text-field class="mt-4" v-model="data_inicio_" outlined label="Data início" type="date"
                clearable></v-text-field></v-col>

        <v-col cols="4">
            <v-text-field v-model="data_fim_" outlined label="Data fim" type="date" clearable
                class="mt-4"></v-text-field></v-col>
        <v-col cols="12">
        
            <v-data-table :loading="loading" :items="filtroequipe" :search="search" :headers="headers"
                :single-expand="singleExpand" :expanded.sync="expanded" show-expand>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="py-4 grey darken-2 white--text text-start">  
                        <v-row dense>
                            <v-col >
                                <p>Criado em: <b>{{ item.created_at }}</b></p>
                                <p>Criado por: <b>{{ item.created_by }}</b></p>
                                <p>Editado em: <b>{{ item.modified_at}}</b></p>
                                <p>Modificado por: <b>{{ item.modified_by}}</b></p>
                            </v-col>
                            <v-col>
                                <p>CPF: <b>{{ item.cpf }}</b></p>
                                <p>RG: <b>{{ item.rg }}</b></p>
                                <p v-if="item.conselho">Conselho: <b>{{ item.conselho.nome }}</b></p>
                                <p v-else>Conselho: <b> Favor cadastrar conselho</b></p>
                                <p v-if="item.conselho">UF do Conselho: <b>{{ item.uf_inscricao_conselho.sigla }}</b>
                                </p>
                                <p v-else>UF do Conselho: <b>{{ }}</b></p>
                                <p>Nº do Conselho: <b>{{ item.nconselho }}</b></p>
                            </v-col>
                            <v-col v-if="item.dados_bancarios.length>0">
                                <div v-for="(i, k) in item.dados_bancarios" :key="k">
                                    <p>Dados Bancários</p>
                                    <p>Prefêrencia de Recebimento:</p>
                                    <p>Banco: <b>{{i.banco }}</b></p>
                                    <p>Agência: <b>{{i.agencia }}</b></p>
                                    <p>Conta CC ou CP: <b>{{i.conta }}</b></p>
                                    <p>Conta e Digito: <b>{{i.conta }} - {{i.digito }}</b></p>
                                    <p>PIX: <b>{{i.pix }}</b></p>
                                </div>
                            </v-col>
                            <v-col v-else>
                                Favor cadastrar dados bancários :)
                            </v-col>
                        </v-row>
                    </td>
                </template>

                <template v-slot:top>
                    <v-row dense>
                        <v-col cols="10">
                            <v-text-field v-model="search" label="Pesquisar" class="mx-0"></v-text-field>
                        </v-col>
                        <v-col cols="1" class="text-right">
                            <TabConfig :current-item="{}" />
                        </v-col>
                        <v-col cols="1" class="text-right">
                            <v-btn icon @click="get_usuario()"><v-icon>mdi-refresh</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </template>

                <template v-slot:[`item.papeis`]="{ item }">
                    <span v-for="i in item.papel" :key="`papel-${i.id}`">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip v-bind="attrs" v-on="on" outlined :color="i.cor" v-if="i.ativo" class="ma-2">
                                    {{ i.titulo }}
                                    <v-icon>{{ i.icon }}</v-icon>
                                </v-chip>
                            </template>
                            <span>{{ i.titulo }}</span>
                        </v-tooltip>
                    </span>
                </template>

                <template v-slot:[`item.edit`]="{ item }">
                    <TrocaSenha :current-item="item" />
                </template>

                <template v-slot:[`item.config`]="{ item }">
                    <TabConfig :current-item="item" />
                </template>
                <!-- colocar o formato de data na parte de baixo -->
                <template v-slot:[`item.nascimento`]="{ item }">
                    {{ item.nascimento | formatData }}
                </template>
            </v-data-table>
        </v-col>
    </v-row>
</template>
<script>
import api from '@/http'
export default {
    name: 'Equipe',
    components: {
        TrocaSenha: () => import("./componentes/equipe_multidisciplinar/TrocaSenha.vue"),
        TabConfig: () => import("./componentes/equipe_multidisciplinar/Tab.vue")
    },
    data: () => ({
        search: '',
        loading: false,
        expanded: [],
        singleExpand: false,
        data_inicio_: '',
        data_fim_: '',
        items: [],
        headers: [
            { text: 'Id', align: 'start', sortable: true, value: 'id', },
            { text: 'Nome Completo', align: 'start', sortable: true, value: 'first_name', },
            { text: 'Especialidade', align: 'start', sortable: true, value: 'especialidade.nome', },
            { text: 'Data de Nascimento', align: 'end', sortable: true, value: 'nascimento' },
            { text: 'Telefone', align: 'start', sortable: true, value: 'tel' },
            { text: 'E-mail', align: 'start', sortable: true, value: 'emailequipe', },
            { text: 'Usuário', align: 'end', sortable: true, value: 'username', },
            { text: 'Carimbo', width:"50", align: 'start', sortable: true, value: 'carimbo' },
            { text: 'Mudar Senha', align: 'end', sortable: true, value: 'edit', },
            { text: 'Edit', align: 'end', sortable: true, value: 'config', },
        ],
    }),
    methods: {
        if_dados_bancarios(val){
            console.log(val)
            if(val !==undefined){
                return val.dados_bancarios
            }
            return ''
        },
        get_usuario() {
            this.loading = true
            api.get('/contas/usuarios')
                .then(response => {
                    this.items = response.data
                })
                .finally(() => {
                    this.loading = false
                })
        },
        val_novo_usuario() {
            this.get_usuario()
        },
    },
  
    mounted() {
        this.get_usuario()
    },
    computed: {
        filtroequipe() {

            let c = this.items.filter((e) => {
                return this.data_inicio_ >= e.nascimento && this.data_fim_ <= e.nascimento

            })

            if (c.length == 0) {
                // b = this.items
                c = this.items

            }
            return c
        }

    },
}
</script>